<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" ref="content">
      <div class="test-title">
        <strong>零岁至三岁婴幼儿词汇调查表</strong>
      </div>
      <div class="tips">
        <p class="tx-i2">1. 请您依据婴幼儿能够「听懂」或「说出」的下列词汇，勾选本调查表。若是意思相同但说法不同，请在「说出」字段里写下婴幼儿「如何说」的替代词汇；若是「仿说」则不列入会说出的词汇。</p>
        <p class="tx-i2">2. 如果您的孩子可以听懂或已经会说出的词汇不在表中，您可以新增其他词汇。此词汇调查表旨在让家长了解孩子目前可以掌握的词汇数量。</p>
        <p class="tx-i2">3. 此表所收集的数据供进阶评估报告使用。</p>
      </div>
      <div v-if="pageNum==index" class="item words-box" v-for="(word, index) in data" :key="index">
        <div class="h3">{{word.name}}</div>
        <ul class="answer-option" v-for="(item, index1) in word.list" :key="index1">
          <li style="min-width: 150px;" :class="{on: item.new == 1}">
            <span>{{index1 + 1}}. </span><span>{{item.word}}</span>
          </li>
          <li>
            <input type="checkbox" :id="word.type + '_know' + index1" v-model="item.know">
            <label :for="word.type + '_know' + index1">听懂</label>
          </li>
          <li>
            <input type="checkbox" :id="word.type + '_speak' + index1" v-model="item.speak">
            <label :for="word.type + '_speak' + index1">说出</label>
          </li>
          <li v-if="item.new != 1">
            <span>如何说</span>
            <input type="text" v-model="item.speak_content">
          </li>
          <li v-if="item.new == 1">
            <span class="del" @click="delWords(index, index1)">删除</span>
          </li>
        </ul>
        <div class="addwords">
          <input type="text" v-model="newWords[word.type]" placeholder="请输入新词汇">
          <span @click="addWords(index, word.type)">添加新词汇</span>
        </div>
      </div>
      <div class="btn-box">
        <button type="button" @click="prevStep()" v-show="preShow">上一类</button>
        <button type="button" @click="nextStep()" v-show="lastShow">下一类</button>
      </div>
      <div class="btn-box">
        <button v-if="subBtn" type="button" @click="submit">提交</button>
      </div>
    </div>
    <!-- 弹窗说明 -->
    <div class="model" v-if="modelShow">
      <h2>指导语</h2>
      <div class="model-spec" style="text-indent: 0px;">
        <p style="　text-indent: -1.2em;margin-left: 1.2em;">1. 本调查表由家长自行填答。</p>
        <p style="　text-indent: -1.2em;margin-left: 1.2em;">2.
          请您依据婴幼儿能够「听懂」或「说出」下列词汇，进行点选。若是意思相同但说法不同，请在「说出」字段里写下婴幼儿「如何说」的替代词汇；若是「仿说」则不列入会说出的词汇。</p>
        <p style="　text-indent: -1.2em;margin-left: 1.2em;">3.
          如果孩子可以听懂或已经会说出的词汇不在表中，您可以新增其他词汇。此词汇调查表旨在让家长了解孩子目前可以掌握的词汇数量。<br>
        </p>
        <p style="　text-indent: -1.2em;margin-left: 1.2em;">4.
          此表所收集之数据供进阶评估报告使用。<br>
          </p>
      </div>
      <div class="model-btn">
        <span @click="modelShow = false">开始答题</span>
      </div>
    </div>
    <div class="model-blank" v-if="modelShow"></div>
    <!--底部-->
    <footer-nav></footer-nav>
    <!-- my confirm -->
    <my-confirm ref="myComfirm"></my-confirm>
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'
  import MyConfirm from '@/components/MyConfirm'
  import Words from '../libs/words'

  export default {
    data() {
      return {
        pageNum: 0,
        preShow: false,
        lastShow: true,
        subBtn: false,
        current: 'evaluation',
        data: [],
        modelShow: true,
        exam_id: '',
        id: '',
        newWords: {
          normal: '',
          cloth: '',
          position: '',
          pron: '',
          body: '',
          food: '',
          animal: '',
          adj: '',
          verb: '',
          person: '',
          social: '',
          other: ''
        }
      }
    },
    watch: {
      pageNum: function () {
        if (this.pageNum != 0) {
          this.preShow = true
        }
        if (this.pageNum == this.data.length - 1) {
          this.subBtn = true
        } else {
          this.subBtn = false
          this.lastShow = true
        }

      }
    },
    methods: {
      //下一类按钮
      nextStep() {
        console.log(this.pageNum);
        if (this.pageNum < this.data.length - 1) {
          this.pageNum++
        }
        if (this.pageNum == this.data.length - 1) {
          this.lastShow = false
          this.$layer.msg('已经是最后一类了')
          return
        }
      },
      // 上一类
      prevStep() {
        if (this.pageNum <= 1) {
          this.preShow = false
        }
        this.pageNum--
      },
      submit: function () {
        let words = [];
        for (let i = 0; i < this.data.length; i++) {
          for (let j = 0; j < this.data[i].list.length; j++) {
            words.push(this.data[i].list[j])
          }
        }
        let params = {
          exam_id: this.exam_id,
          data: words
        };
        this.$post('exam/submitWords', params).then((res) => {
          if (res.code === 1) {
            this.resultTips()
          } else {
            this.$layer.msg(res.msg)
          }
        })
      },
      addWords(index, type) {
        if (this.newWords[type] == '') {
          this.$layer.msg('请输入新词汇');
          return false
        }
        let item = {};
        item.type = type;
        item.word = this.newWords[type];
        item.know = false;
        item.speak = false;
        item.speak_content = ''
        item.new = 1;
        this.data[index].list.push(item);
        this.newWords[type] = ''
      },
      delWords(index, index1) {
        this.data[index].list.splice(index1, 1)
      },
      resultTips: function () {
        let confirm = this.$refs.myComfirm;
        confirm.open('温馨提示', {
          content: '测试已完成，进入查看结果?',
          btn: ['测评历史', '查看结果']
        }, () => {
          confirm.hide();
          this.$router.replace('/history')
        }, () => {
          confirm.hide();
          this.$router.replace(`/language?eid=` + this.exam_id + '&id=' + this.id)
        })
      }
    },
    components: {
      HeaderNav,
      FooterNav,
      MyConfirm
    },
    mounted: function () {
      var h = document.documentElement.clientHeight || document.body.clientHeight;
      this.$refs.content.style.minHeight = h - 144 + 'px'

    },
    created() {
      if (this.$route.query.eid) {
        this.exam_id = this.$route.query.eid
      }
      if (this.$route.query.id) {
        this.id = this.$route.query.id
      }
      for (let i = 0; i < Words.length; i++) {
        let word = [];
        word.name = Words[i].name;
        word.type = Words[i].type;
        for (let j = 0; j < Words[i].list.length; j++) {
          let item = {};
          item.type = Words[i].type;
          item.word = Words[i].list[j];
          item.know = false;
          item.speak = false;
          item.speak_content = '';
          item.new = 0;
          word.push(item)
        }
        word.list = word;
        this.data.push(word)
      }
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/test');
</style>
