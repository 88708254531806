const Words = [
    {
        name: '一般名词',
        type: 'normal',
        list: ['水', '口水', '小孩', '朋友', '大家', '别人', '宝宝', '娃娃', '熊熊', '怪兽', '积木', '玩具', '车', '摩托车', '汽车', '火车', '飞机', '生日', '生日快乐', '球', '气球', '花', '礼物', '贝壳', '剪刀', '眼镜', '蜡烛', '钥匙', '背包', '内裤', '卫生纸', '钱', '杯子', '茶杯', '碗', '汤匙', '筷子', '桌子', '椅子', '毛巾', '牙刷', '牙膏', '肥皂', '乳液', '糖果', '豆子', '电话', '电视', '冰箱', '电灯', '计算机', '浴室', '家', '房子', '公园', '百货公司', '地方', '荡秋千', '溜滑梯', '红灯', '绿灯', '天线', '太阳', '颜色', '分钟', '明天', '东西', '什么']
    },
    {
        name: '衣物名称',
        type: 'cloth',
        list: ['衣服', '外套', '裤子', '裙子', '鞋子', '袜子', '帽子', '尿布／布布', '毛巾']
    },
    {
        name: '方位词',
        type: 'position',
        list: ['上面', '下面', '里面', '外面', '后面', '这里', '那里', '哪里', '这边']
    },
    {
        name: '代词',
        type: 'pron',
        list: ['你', '我', '他', '你们', '我们', '他们', '这个', '那个']
    },
    {
        name: '身体部位名称',
        type: 'body',
        list: ['手', '左手', '眼睛', '鼻子', '嘴巴', '牙齿', '耳朵', '头', '头发', '脖子', '身体', '肚子', '屁股', '脚']
    },
    {
        name: '食物名称',
        type: 'food',
        list: ['饼干', '牛奶', '冰淇淋', '巧克力', '酸奶', '蛋糕', '米粉', '豆腐', '玉米', '香菇', '萝卜', '菜', '肉', '火腿', '面包', '炸鸡', '汉堡', '薯条', '浓汤', '早餐', '果汁', '水果', '苹果', '香蕉', '西瓜', '草莓', '橘子', '葡萄', '柠檬', '樱桃']
    },
    {
        name: '动物名称',
        type: 'animal',
        list: ['小狗／汪汪', '小猫／猫咪', '小鸟', '白兔', '老鼠', '青蛙', '乌龟', '鱼', '鳄鱼', '鸭子', '蟑螂', '蝴蝶', '猴子', '牛', '绵羊', '大象', '长颈鹿', '猩猩', '狐狸', '老虎', '恐龙']
    },
    {
        name: '形容词',
        type: 'adj',
        list: ['多', '少', '一点', '大／大的', '小／小的', '怕怕', '臭臭', '脏脏', '干净', '漂亮／漂漂', '好吃', '好听', '好看', '好笑', '好像', '厉害', '安静', '舒服', '红色', '黄色', '白色', '蓝色', '绿色', '圆形']
    },
    {
        name: '动作词',
        type: 'verb',
        list: ['要', '不要', '来', '去', '穿', '脱', '拿', '玩', '喝', '抱抱', '拍手', '给我', '开／关', '打开', '关起来', '起来', '坐下', '进去', '走开', '出去', '上去', '下去', '找到', '找不到', '丢／丢掉', '看', '看到', '听', '说', '讲话', '知道', '大便／小便', '用水', '洗／洗澡', '洗手', '洗头', '刷牙', '洗衣服', '扫地／扫', '擦干', '吃饭', '睡觉', '唱歌', '跳舞', '看书', '弹琴', '打计算机', '游泳', '运动', '走路', '跌倒', '撞到', '保护', '回家', '回来', '敲门', '高兴', '喜欢', '生气', '恭喜', '帮忙', '修理', '照相', '录音', '上课', '上学', '上班', '工作', '生病', '看病', '打针', '擦药', '下雨']
    },
    {
        name: '称谓／名字',
        type: 'person',
        list: ['自己／自己名字', '爸爸', '妈妈', '爷爷', '奶奶', '外公／姥爷', '外婆／姥姥', '哥哥', '姐姐', '弟弟', '妹妹', '儿子', '先生', '阿姨', '舅舅', '小朋友', '老公公', '老师', '警察']
    },
    {
        name: '社交语',
        type: 'social',
        list: ['嗨', '早上好 ', '晚安 ', '谢谢', '不客气', '对不起', '等一下', 'bye bye／再见']
    },
    {
        name: '其他词汇',
        type: 'other',
        list: ['一起', '一样', '一边', '小心', '很', '好', '可以', '真的', '不知道', '等一下', '已经', '因为', '而已', '还有', '还要', '没有', '不会', '不是', '不行', '不到', '不可以', '比较（大小）', '地方名词', '怎么', '刚刚', '现在', '这样', '怎么办']
    }
]
export default Words
